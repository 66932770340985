import * as React from 'react';
import { ReactNode } from 'react';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { RenderMarkdown } from '@blockworks/ui/legacy/html';
import { Link } from '@blockworks/ui/legacy/link';

import AdContainer from '@/components/ads/ad-container';
import AdContainerIds from '@/components/ads/ad-container-ids';
import { AudioProvider } from '@/components/pages/podcast/layout/audio-provider';
import { AudioPlayer } from '@/components/player/audio-player';
import { NewsArchiveProvider } from '@/context/news-archive-ctx';
import { useOptions } from '@/context/options';
import Footer from '@/layout/containers/default-container/footer';
import MainMenu from '@/layout/containers/default-container/main-menu/main-menu';
import Ticker from '@/layout/containers/default-container/ticker/ticker';
import type { GlobalOptionProps } from '@/types/_app';
import FeatureFlags from '@/types/feature-flags';
import usePriceTicker from '@/utils/hooks/use-price-ticker';

import { footerMenuCompany, footerMenuSections } from './main-menu/const';

type DefaultContainerProps = {
    meta: ReactNode;
    options: GlobalOptionProps;
    featureFlags: FeatureFlags;
    children?: ReactNode;
};

/**
 * @param options
 */
const tickerSponsor = (options: GlobalOptionProps) => {
    if (options.tickerImageUrl) {
        return (
            <Link
                href={options.tickerUrl}
                prefetch={false}
                className="flex flex-row justify-start items-center relative gap-2"
                target="_blank"
                rel="nofollow"
            >
                <span className="flex text-xxs lowercase text-light-gray">sponsored by</span>
                <ImageWithFallback src={options.tickerImageUrl} alt={'Ticker Sponsor'} width={120} height={20} />
            </Link>
        );
    }

    return <AdContainer googleAd={AdContainerIds.Ticker120x20!} />;
};

const DefaultContainer = (props: DefaultContainerProps) => {
    const { meta, children } = props;
    const tickerItems = usePriceTicker();
    const options = useOptions();

    /*
const navigation: INavigationItem[] = [
{name: 'Home', url: '/', icon: HomeIcon, current: router.pathname === '/'},
];
*/
    return (
        <div className="relative flex flex-col justify-start items-start 2xl:border-l 2xl:border-r border-gray-200 max-w-screen-2xl mx-auto">
            {meta}
            <header className="w-full border-b border-gray-200">
                <div className="text-xs">
                    {options.config.alertBarContent ? (
                        <div className="top-bar bg-brand min-h-[2.5rem]">
                            <RenderMarkdown
                                text={options.config.alertBarContent || '&nbsp;'}
                                className="text-xxs md:text-xs xl:text-sm"
                            />
                        </div>
                    ) : (
                        <div className="top-bar bg-brand min-h-[1rem]" />
                    )}
                    <NewsArchiveProvider>
                        <MainMenu featureFlags={options.featureFlags} />
                    </NewsArchiveProvider>
                    <div className="h-10 overflow-hidden">
                        <Ticker items={tickerItems} sponsor={tickerSponsor(options.config)} />
                    </div>
                </div>
            </header>

            <AudioProvider>
                {children}

                <div className="fixed bottom-0 z-10 left-0 right-0">
                    <AudioPlayer />
                </div>
            </AudioProvider>

            <Footer
                address="133 W 19th St., New York, NY 10011"
                footerMenuSections={footerMenuSections}
                footerMenuCompany={footerMenuCompany}
            />
        </div>
    );
};

export default DefaultContainer;
