import * as React from 'react';
import { Fragment, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { useTrack } from '@blockworks/platform/services/analytics';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import LogoBlack from '@/assets/images/logo-black';
import MainMenuItem from '@/layout/containers/default-container/main-menu/main-menu-item';
import ResponsiveBurgerMenu from '@/layout/containers/default-container/main-menu/responsive-burger-menu';
import FeatureFlags from '@/types/feature-flags';

import { brandsCategoryItems, mainMenu } from './const';

export type MenuItem = {
    name: string;
    url: string;
    id?: number;
    className?: string;
};

type MainMenuProps = {
    className?: string;
    featureFlags?: FeatureFlags;
};

const MainMenu = (props: MainMenuProps) => {
    const { className } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const handleClose = () => buttonRef.current?.click();
    const track = useTrack();

    const clickedHeaderLogo = () => {
        track('Clicked Header Logo');
    };

    return (
        <div
            className={cn(
                'flex lg:justify-between lg:items-center w-full relative p-6 border-b border-gray-200',
                className,
            )}
        >
            <div className="flex basis-auto lg:basis-1/3">
                <ResponsiveBurgerMenu menuItems={brandsCategoryItems} mainMenu={mainMenu} />
                <div className="flex justify-end items-center relative gap-3">
                    <div className="gap-1 hidden lg:block">
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        ref={buttonRef}
                                        className={cn(
                                            open ? 'text-gray-900' : 'text-black',
                                            'group bg-white rounded-md inline-flex items-center font-medium hover:text-gray-900 focus:outline-none',
                                        )}
                                    >
                                        <span className="uppercase hover:text-light-gray">Brands</span>
                                        <ChevronDownIcon
                                            className={cn(
                                                open ? 'text-gray-600' : 'text-gray-400',
                                                'ml-0.5 h-5 w-5 group-hover:text-black',
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute border-t border-r border-b z-20 -ml-4 mt-2 transform px-2 w-auto sm:px-0 lg:ml-0 lg:left-0 lg:-translate-x-6">
                                            <div className="relative gap-6 min-w-[275px] bg-white px-5 py-6 sm:gap-6 sm:p-6">
                                                {brandsCategoryItems.map(item => (
                                                    <Link
                                                        prefetch={false}
                                                        key={item.name}
                                                        href={item.url}
                                                        onClick={() => handleClose()}
                                                        className={
                                                            item.className
                                                                ? item.className
                                                                : 'flex p-2 items-start hover:bg-gray-50 w-full'
                                                        }
                                                    >
                                                        <span className="ml-2 text-xs font-medium text-gray-900">
                                                            {item.name}
                                                        </span>
                                                    </Link>
                                                ))}
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    </div>
                    <MainMenuItem className="hidden lg:block" name="newsletters" url="/newsletter" />
                    <MainMenuItem className="hidden lg:block" name="podcasts" url="/podcasts" />
                    <MainMenuItem className="hidden lg:block" name="events" url="/events" />
                    <MainMenuItem className="hidden lg:block" name="roundtables" url="/roundtables" />
                </div>
            </div>
            <div className="mx-6 grow -mt-0.5 lg:mx-0 basis-auto lg:basis-1/3">
                <Link href="/" aria-label="Homepage" onClick={() => clickedHeaderLogo()}>
                    <LogoBlack className="mx-auto sm:mx-0 lg:mx-auto" />
                </Link>
            </div>
            <div className="flex basis-auto lg:basis-1/3 justify-end ">
                <div className="justify-end items-center relative gap-3 hidden sm:flex">
                    <MainMenuItem className="hidden md:flex" name="etf tracker" url="/analytics/bitcoin-etf/tracker" />
                    <MainMenuItem className="hidden md:flex" name="prices" url="/prices" />
                    <MainMenuItem
                        name="research"
                        url="https://blockworksresearch.com/?ref=blockworks.co"
                        openInNewWindow
                    />
                    <MainMenuItem
                        className="hidden md:flex"
                        name="analytics"
                        url="/analytics"
                        linkClassName="block uppercase text-white bg-brand hover:bg-white hover:text-brand whitespace-nowrap border border-brand py-1 px-2"
                    />
                </div>
                <div className="flex justify-end items-center relative gap-3 ml-4">
                    <Link
                        prefetch={false}
                        href="/search"
                        className="block hover:text-gray-500 text-gray-700"
                        aria-label="Search"
                    >
                        <svg
                            width={18}
                            height={19}
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0"
                            preserveAspectRatio="none"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.75 0.818848C10.4779 0.818848 13.5 3.84092 13.5 7.56885C13.5 9.20359 12.9189 10.7026 11.9519 11.8706L18 17.9188L17.1 18.8188L11.0517 12.7708C9.88371 13.7377 8.38474 14.3188 6.75 14.3188C3.02207 14.3188 0 11.2967 0 7.56885C0 3.84092 3.02207 0.818848 6.75 0.818848ZM6.75 1.71885C3.51914 1.71885 0.9 4.33798 0.9 7.56885C0.9 10.7998 3.51914 13.4188 6.75 13.4188C9.98091 13.4188 12.6 10.7998 12.6 7.56885C12.6 4.33798 9.98091 1.71885 6.75 1.71885Z"
                                fill="currentColor"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default MainMenu;
