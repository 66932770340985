/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import Logo from '@/assets/images/logo';
import type { MenuItem } from '@/layout/containers/default-container/main-menu/main-menu';

type ResponsiveBurgerMenuProps = {
    menuItems: MenuItem[];
    mainMenu: MenuItem[];
    className?: string;
};

const ResponsiveBurgerMenu = (props: ResponsiveBurgerMenuProps) => {
    const { menuItems, className, mainMenu } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const handleClose = () => buttonRef.current?.click();

    return (
        <Popover className={cn('relative', className)}>
            <div className="lg:hidden -p-2 -m-1.5">
                <Popover.Button
                    ref={buttonRef}
                    className="p-2 inline-flex items-center justify-center text-black hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand rounded-md"
                >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute top-0 inset-x-0 p-0 -m-6 md:-m-4 transition transform origin-top-right lg:hidden z-10"
                >
                    <div className="md:rounded-lg md:shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 w-96 p-2">
                        <div className="pt-4 pb-6 px-4">
                            <div className="flex items-center justify-between">
                                <Logo className="" />
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand">
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6 px-1">
                                <nav className="grid grid-cols-2 gap-y-4 gap-x-8 md:gap-x-8">
                                    {menuItems.map(item => (
                                        <Link
                                            prefetch={false}
                                            key={item.name}
                                            href={item.url}
                                            className={
                                                item.className ||
                                                'p-3 flex items-center font-medium text-gray-900 rounded-md hover:bg-gray-50'
                                            }
                                            onClick={() => handleClose()}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5 space-y-6">
                            <nav className="grid grid-cols-2 gap-y-4 gap-x-8">
                                {mainMenu.map(item => (
                                    <Link
                                        prefetch={false}
                                        key={item.name}
                                        href={item.url}
                                        className={
                                            item.className ||
                                            'p-3 flex items-center rounded-md hover:text-gray-900 font-medium text-gray-900'
                                        }
                                        onClick={() => handleClose()}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </nav>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default ResponsiveBurgerMenu;
