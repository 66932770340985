import '@/assets/styles/global.css';

import * as React from 'react';
import { useState } from 'react';
import { get } from '@vercel/edge-config';
import App, { AppContext, AppProps } from 'next/app';
import Router from 'next/router';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import NProgress from 'nprogress';
import { SWRConfig } from 'swr';

import { HistoryProvider } from '@blockworks/platform/services/history';

import AdsProvider from '@/context/ads';
import { AlgoliaProvider } from '@/context/algolia';
import OptionsProvider from '@/context/options';
import { QueryProvider } from '@/context/query-provider';
import EditorialConfig from '@/editorial-config';
import DefaultContainer from '@/layout/containers/default-container';
import Analytics from '@/layout/containers/default-container/analytics';
import Scripts from '@/layout/containers/default-container/scripts';
import { DefaultMeta } from '@/layout/head/default-meta';
import type { GlobalOptionProps, IComponent, MetaData, PageProps } from '@/types/_app';
import type FeatureFlags from '@/types/feature-flags';

// Global App's properties
export type BlockworksCoAppProps = AppProps & {
    // Content component (from /pages/)
    Component: IComponent<React.ReactNode>;
    // Page properties, usually holds initial data from SSR and meta information
    pageProps: PageProps;
    // Feature Flags
    featureFlags?: FeatureFlags;
    options?: GlobalOptionProps;
};

// Provide default meta tags
const MetaDefault: MetaData = {
    title: EditorialConfig.site_name,
    description: EditorialConfig.description,
};

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const BlockworksCoApp = (appProps: BlockworksCoAppProps) => {
    // Receive content Component and pagePropsœ
    const { Component, pageProps } = appProps;
    const [, setSession] = useState({ userId: '', name: '' });
    // DefaultContainer and DefaultMeta is used if not otherwise specified
    const Layout = Component.Layout || DefaultContainer;
    const Meta = Component.Meta || DefaultMeta;

    // Fetch meta properties from pageProps or use the default
    const metaProps = pageProps.meta || MetaDefault;
    // Return render output
    return (
        <>
            <SWRConfig value={pageProps}>
                <QueryProvider>
                    <AlgoliaProvider>
                        <Analytics meta={metaProps}>
                            <HistoryProvider>
                                <OptionsProvider featureFlags={appProps.featureFlags || {}}>
                                    <AdsProvider>
                                        <ReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITEKEY} useRecaptchaNet>
                                            <Layout
                                                {...pageProps}
                                                options={appProps.options}
                                                meta={<Meta {...metaProps} />}
                                            >
                                                <Component
                                                    {...pageProps}
                                                    options={appProps.options}
                                                    updateSession={setSession}
                                                />
                                                <Scripts />
                                            </Layout>
                                        </ReCaptchaProvider>
                                    </AdsProvider>
                                </OptionsProvider>
                            </HistoryProvider>
                        </Analytics>
                    </AlgoliaProvider>
                </QueryProvider>
            </SWRConfig>
        </>
    );
};

BlockworksCoApp.getInitialProps = async (appContext: AppContext) => {
    const initialProps: any = await App.getInitialProps(appContext);
    let props = initialProps;

    if (typeof window === 'undefined') {
        let featureFlags = {};
        try {
            featureFlags = (await get('features')) || {};
        } catch (_error) {
            /* empty */
        }
        props = {
            ...props,
            featureFlags,
        };
    }

    return props;
};

export default BlockworksCoApp;
