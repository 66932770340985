import { MenuItem } from './main-menu';

export const mainMenu: MenuItem[] = [
    {
        name: 'Latest News',
        url: '/news',
    },
    {
        name: 'Newsletter',
        url: '/newsletter',
    },
    {
        name: 'Podcasts',
        url: '/podcasts',
    },
    {
        name: 'Events',
        url: '/events',
    },
    {
        name: 'Roundtables',
        url: '/roundtables',
    },
    {
        name: 'Prices',
        url: '/prices',
    },
    {
        name: 'Analytics',
        url: '/analytics',
    },
    {
        name: 'Bitcoin ETF Tracker',
        url: '/analytics/bitcoin-etf/tracker',
    },
    {
        name: 'Research',
        url: 'https://app.blockworksresearch.com',
    },
];

export const brandsCategoryItems: MenuItem[] = [
    {
        name: '0xResearch',
        url: '/brand/0xresearch',
    },
    {
        name: '1000x',
        url: '/podcast/1000x',
    },
    {
        name: 'Bell Curve',
        url: '/podcast/bellcurve',
    },
    {
        name: 'Blockworks Daily',
        url: '/newsletter/daily',
    },
    {
        name: 'Empire',
        url: '/brand/empire',
    },
    {
        name: 'Expansion',
        url: '/podcast/expansion',
    },
    {
        name: 'Forward Guidance',
        url: '/brand/forward-guidance',
    },
    {
        name: 'Lightspeed',
        url: '/brand/lightspeed',
    },
    {
        name: 'Supply Shock',
        url: '/brand/supply-shock',
    },
    {
        name: 'The Breakdown',
        url: '/podcast/thebreakdown',
    },
    {
        name: 'The Drop',
        url: '/newsletter/thedrop',
    },
];

export const subMenu = [{ name: 'News Archive', url: '/news' }];

export const footerMenuSections = [
    {
        name: 'News',
        url: '/news',
    },
    {
        name: 'Podcasts',
        url: '/podcasts',
    },
    {
        name: 'Newsletters',
        url: '/newsletter',
    },
    {
        name: 'Events',
        url: '/events',
    },
    {
        name: 'Roundtables',
        url: '/roundtables',
    },
    {
        name: 'Analytics',
        url: '/analytics',
    },
    {
        name: 'Sitemap',
        url: '/sitemap',
    },
];

export const footerMenuCompany = [
    {
        name: 'About',
        url: '/about',
    },
    {
        name: 'Advertise',
        url: '/advertise',
    },
    {
        name: 'Careers',
        url: '/careers',
    },
    {
        name: 'Trust & Ethics',
        url: '/trust-ethics',
    },
    {
        name: 'Privacy Policy',
        url: '/privacy-policy',
    },
    {
        name: 'Contact Us',
        url: '/contact-us',
    },
];
