import * as React from 'react';

import { useTrack } from '@blockworks/platform/services/analytics';
import { Link } from '@blockworks/ui/legacy/link';

import TickerItem from '@/layout/containers/default-container/ticker/ticker-item';

export type TickerItemType = {
    name: string;
    value: number | null;
    percentage: number | null;
    type: string;
};

type TickerProps = {
    items: TickerItemType[];
    sponsor: JSX.Element | null;
};

const TopBarTicker = (props: TickerProps) => {
    const { items, sponsor } = props;
    const track = useTrack();

    const renderItems = (key: string) => {
        return items.map(item =>
            item.type === 'crypto' ? (
                <Link
                    key={`${item.name}-${key}`}
                    href={`/price/${item.name?.toLowerCase()}`}
                    prefetch={false}
                    className="hover:text-brand"
                    onClick={() => track('Click.TickerItem', item)}
                >
                    <TickerItem
                        name={item.name}
                        value={item.value}
                        percentage={item.percentage}
                        className="self-start flex-none py-1"
                    />
                </Link>
            ) : (
                <TickerItem
                    key={item.name}
                    name={item.name}
                    value={item.value}
                    percentage={item.percentage}
                    className="self-start flex-none py-1 cursor-default"
                />
            ),
        );
    };
    return (
        <>
            <div className="py-2 px-0 m-0 top-ticker w-full flex gap-0 min-h-[24px]">
                <div className="top-ticker-items flex justify-start items-center gap-10 whitespace-nowrap">
                    {renderItems('original')}
                    {sponsor && (
                        <div className="mx-auto flex align-center items-center text-center">
                            <>{sponsor}</>
                        </div>
                    )}
                </div>
                <div className="top-ticker-items top-ticker-items-second flex justify-start items-center gap-10 whitespace-nowrap">
                    {renderItems('clone')}
                    <div className="w-[60px]" />
                </div>
            </div>
        </>
    );
};

export default TopBarTicker;
