import { fetcher } from '@blockworks/platform/api';
import { BeehiivCustomField, BeehiivPublicationIds } from '@blockworks/platform/api/beehiiv';

export const allNewsletters: BeehiivPublicationIds[] = [
    BeehiivPublicationIds.Daily,
    BeehiivPublicationIds.Weekly,
    BeehiivPublicationIds.Events,
    BeehiivPublicationIds.Webinars,
    BeehiivPublicationIds.ZeroXResearch,
    BeehiivPublicationIds.Empire,
    BeehiivPublicationIds.Lightspeed,
    BeehiivPublicationIds.ForwardGuidance,
    BeehiivPublicationIds.TheDrop,
    BeehiivPublicationIds.SupplyShock,
];

/**
 * Subscribes email to newsletter
 *
 * @param email
 * @param subscribeTo
 * @param source
 * @param sourcePath
 * @param campaign
 * @param medium
 * @param customFields
 * @param captchaToken
 */
const subscribeToNewsletter = async ({
    email,
    subscribeTo = allNewsletters,
    source = 'Website',
    sourcePath = '',
    campaign = '',
    medium = 'organic',
    customFields,
    captchaToken = '',
}: {
    email: string;
    subscribeTo: BeehiivPublicationIds[];
    source: string;
    sourcePath: string;
    campaign?: string;
    medium?: string;
    customFields?: BeehiivCustomField[];
    captchaToken?: string;
}) => {
    if (!email) {
        return Promise.reject(new Error('Email address required'));
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email)) {
        return Promise.reject(new Error('Invalid email address'));
    }

    return fetcher<false, any, any>({
        url: '/api/newsletter/signup',
        body: {
            email,
            subscribeTo,
            source,
            sourcePath,
            campaign,
            medium,
            customFields,
            captchaToken,
        },
        method: fetcher.Method.Post,
    });
};

export default subscribeToNewsletter;
