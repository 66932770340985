import * as React from 'react';

import FooterBottomSection from '@/layout/containers/default-container/footer/footer-bottom-section';
import FooterMenu, { FooterMenuItem } from '@/layout/containers/default-container/footer/footer-menu';
import FooterTopSection from '@/layout/containers/default-container/footer/footer-top-section';

type FooterProps = {
    address: string;
    footerMenuSections: FooterMenuItem[];
    footerMenuCompany: FooterMenuItem[];
};

const Footer = (props: FooterProps) => {
    const { address, footerMenuCompany, footerMenuSections } = props;

    return (
        <footer className="bg-[color:#110B29] w-full">
            <FooterTopSection />
            <FooterMenu menuItemsCol1={footerMenuSections} menuItemsCol2={footerMenuCompany} />
            <FooterBottomSection address={address} />
        </footer>
    );
};

export default Footer;
