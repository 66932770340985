import * as React from 'react';
import Seo, { PageSchema } from 'react-headless-yoast';
import Head from 'next/head';
import { useRouter } from 'next/router';

import EditorialConfig from '@/editorial-config';
import decodeEntities from '@/utils/functions/string/decode-entities';
import { rightTrim } from '@/utils/functions/string/right-trim';
import replaceCmsUrl from '@/utils/functions/wordpress/replace-cms-url';

interface OpenGraphMedia {
    url: string;
    width?: number | null;
    height?: number | null;
    alt?: string;
    type?: string;
    secureUrl?: string;
}

export interface IMetaProps extends YoastSeo {
    images?: ReadonlyArray<OpenGraphMedia>;

    // Optional article properties that are added to the meta object on article pages
    authors?: string[];
    categories?: string[];
    tags?: string[];
}

interface YoastSeo {
    title?: string;
    description?: string;
    robots?: Robots;
    canonical?: string;
    og_locale?: string;
    og_type?: string;
    og_title?: string;
    og_description?: string;
    og_url?: string;
    og_site_name?: string;
    article_published_time?: string;
    article_modified_time?: string;
    og_image?: OgImage[];
    author?: string;
    twitter_card?: string;
    twitter_creator?: string;
    twitter_site?: string;
    twitter_misc?: TwitterMisc;
    schema?: PageSchema;
}

type TwitterMisc = {
    [key: string]: any;
    'Written by'?: string;
    'Est. reading time'?: string;
};

type OgImage = {
    width?: number;
    height?: number;
    url?: string;
    type?: string;
};

type Robots = {
    index?: string;
    follow?: string;
    'max-snippet'?: string;
    'max-image-preview'?: string;
    'max-video-preview'?: string;
};

const DefaultMeta = (props: IMetaProps) => {
    const router = useRouter();
    let { title } = EditorialConfig;

    // Fix cms. addresses in schema object, but not for wp-content urls (which are images)
    const schema: PageSchema = props.schema
        ? JSON.parse(
              JSON.stringify(props.schema).replace(
                  /"https?:\/\/cms\.blockworks\.co((?!\/wp-content).*?)"/g,
                  '"https://blockworks.co$1"',
              ),
          )
        : null;

    let ogImage: OgImage | null;

    if (props.og_image?.[0]?.url) {
        ogImage = {
            ...props.og_image[0],
            // Replacing og image with nextjs image CDN
            // disabling webp since webp is not supported by Telegram
            url: `${EditorialConfig.SITE_URL}/_next/image?url=${encodeURIComponent(props.og_image[0].url)}&w=1920&q=75&webp=false`,
        };
    } else {
        ogImage = {
            url: `${EditorialConfig.SITE_URL}/images/Blockworks_Meta_Logo.png`,
        };
    }

    if (props.title) {
        if (props.title.indexOf(EditorialConfig.site_name) !== -1) {
            title = decodeEntities(props.title);
        } else {
            title = `${decodeEntities(props.title)} - ${EditorialConfig.site_name}`;
        }
    }

    const description = props.description || EditorialConfig.description;

    // Build canonical url
    const pathSliceLength = Math.min.apply(Math, [
        router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
        router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length,
    ]);

    const canonical = rightTrim(
        replaceCmsUrl(props.canonical) || EditorialConfig.SITE_URL + router.asPath.substring(0, pathSliceLength),
    );

    const metaIterator = [
        <meta charSet="UTF-8" key="charset" />,
        <meta
            key="viewport"
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />,
        <link rel="apple-touch-icon" href={`${EditorialConfig.SITE_URL}/apple-touch-icon.png`} key="apple" />,
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${EditorialConfig.SITE_URL}/favicon-32x32.png`}
            key="icon32"
        />,
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${EditorialConfig.SITE_URL}/favicon-16x16.png`}
            key="icon16"
        />,
        <link key="favicon" rel="icon" href={`${EditorialConfig.SITE_URL}/favicon.ico`} />,
        <link key="manifest" rel="manifest" href={`${EditorialConfig.SITE_URL}/site.webmanifest`} />,
        <link key="safari-pinned-tab" rel="mask-icon" href="/safari-pinned-tab.svg" color="#fff" />,
        <meta key="twitter:card" name="twitter:card" content={props.twitter_card} />,
        <meta key="twitter:creator" name="twitter:creator" content={props.twitter_creator} />,
        <meta key="msapplication-TileColor" name="msapplication-TileColor" content="#ffffff" />,
        <meta key="theme-color" name="theme-color" content="#ffffff" />,
    ];

    const articleGraph = schema?.['@graph']?.find(graph => graph['@type'] === 'Article');
    const metaKeywords = (articleGraph as any)?.keywords?.join(', ') || '';

    if (props.twitter_misc) {
        let i = 1;
        for (const twMisc in props.twitter_misc) {
            if (props.twitter_misc[twMisc]) {
                metaIterator.push(<meta key={`twitter:label${i}`} name={`twitter:label${i}`} content={twMisc} />);
                metaIterator.push(
                    <meta key={`twitter:data${i}`} name={`twitter:data${i}`} content={props.twitter_misc[twMisc]} />,
                );
                i++;
            }
        }
    }

    // Categories
    if (props.categories) {
        metaIterator.push(
            <meta key="article:section" property="article:section" content={props.categories.join(',')} />,
        );
        metaIterator.push(<meta key="article:author" property="article:author" content={props.authors?.join(',')} />);
    }

    return (
        <>
            <Seo
                pageSchema={schema || { '@context': '', '@graph': [] }}
                siteSchema={{
                    companyName: 'Blockworks',
                    companyLogo: {
                        sourceUrl: '/images/favicon/apple-touch-icon.png',
                        altText: 'Blockworks',
                        // srcSet: string,
                    },
                    inLanguage: 'en',
                    siteName: EditorialConfig.site_name,
                    siteUrl: EditorialConfig.SITE_URL,
                }}
                page={{
                    title,
                    locale: 'en-US',
                    seo: {
                        breadcrumbs: {
                            text: '',
                            url: '',
                        },
                        schema: {
                            articleType: '',
                            pageType: '',
                            raw: '',
                        },
                        canonical,
                        cornerstone: '',
                        focuskw: '',
                        metaDesc: description,
                        metaKeywords,
                        metaRobotsNofollow: props.robots?.follow,
                        metaRobotsNoindex: props.robots?.index,
                        opengraphAuthor: props.author,
                        opengraphDescription: props.og_description,
                        opengraphImage: {
                            sourceUrl: ogImage.url,
                            altText: EditorialConfig.site_name,
                            // srcSet: string,
                        },
                        opengraphModifiedTime: props.article_modified_time || new Date().toUTCString(),
                        opengraphPublishedTime: props.article_published_time || new Date().toUTCString(),
                        opengraphPublisher: EditorialConfig.site_name,
                        opengraphSiteName: EditorialConfig.site_name,
                        opengraphTitle: props.og_title,
                        opengraphType: props.og_type,
                        opengraphUrl: canonical,
                        readingTime: props.twitter_misc?.['Est. reading time'] || '',
                        title,
                        twitterDescription: props.og_description,
                        twitterImage: {
                            sourceUrl: ogImage.url,
                            altText: EditorialConfig.site_name,
                            // srcSet: string,
                        },
                        twitterTitle: props.og_title,
                    },
                }}
                MetaRenderElement={Head}
                meta={metaIterator}
            />
        </>
    );
};

export { DefaultMeta };
