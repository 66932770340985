import { CSSProperties, useCallback, useState } from 'react';
import Image, { ImageProps } from 'next/image';

type ImageWithFallbackProps = ImageProps & {
    fallbackSrc?: string;
    style?: CSSProperties;
};

const ImageWithFallback = (props: ImageWithFallbackProps) => {
    const { src, fallbackSrc, style, alt, ...rest } = props;
    const [imgSrc, setImgSrc] = useState(src);

    const handleOnError = useCallback(() => {
        fallbackSrc && setImgSrc(fallbackSrc);
    }, [fallbackSrc]);

    return <Image {...rest} alt={alt} style={style} src={imgSrc} onError={handleOnError} />;
};

export type { ImageWithFallbackProps };
export { ImageWithFallback };
