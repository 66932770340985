import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { fetcher } from '@blockworks/platform/api';
import { useLocalStorage } from '@blockworks/platform/hooks';

interface AlgoliaContextValue {
    algoliaKey: string | null;
    loading: boolean;
    error: boolean;
}

const AlgoliaContext = createContext<AlgoliaContextValue | undefined>(undefined);

interface AlgoliaProviderProps {
    children: React.ReactNode;
}

export const AlgoliaProvider = ({ children }: AlgoliaProviderProps) => {
    const { storedValue: algoliaKey, setValue: setAlgoliaKey } = useLocalStorage<string | null>('algoliaKey', null);
    const { storedValue: storedTTL, setValue: setStoredTTL } = useLocalStorage<number | null>('algoliaTTL', null);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);

    useEffect(() => {
        let isActive = true;
        const retryAttempts = 3;
        const retryDelay = 1000;

        const fetchAlgoliaKey = async () => {
            try {
                setLoading(true);
                let attempts = 0;
                let error;

                while (attempts < retryAttempts) {
                    try {
                        const response = await fetcher<false, void, { apiKey: string; expirationTimestamp: number }>({
                            url: '/api/algolia/secured-key',
                            method: fetcher.Method.Post,
                        });

                        const { apiKey, expirationTimestamp } = response;

                        if (isActive) {
                            setAlgoliaKey(apiKey);
                            setStoredTTL(expirationTimestamp);
                            setError(false);
                        }
                        return;
                    } catch (e) {
                        error = e;
                        attempts++;
                        if (attempts < retryAttempts) {
                            await new Promise(resolve => setTimeout(resolve, retryDelay));
                        }
                    }
                }
                throw error;
            } catch (err: unknown) {
                if (isActive) {
                    console.error(err);
                    setError(true);
                }
            } finally {
                if (isActive) {
                    setLoading(false);
                }
            }
        };

        // Edge case when user deletes storedTTL or is missing for some reason
        if (!storedTTL) {
            fetchAlgoliaKey();
            return;
        }

        // Check if the apiKey or TTL is missing or expired
        const isExpired = storedTTL !== null && Math.floor(Date.now() / 1000) >= storedTTL;

        if (!algoliaKey || isExpired) {
            fetchAlgoliaKey();
            return;
        }

        // Refetches a new key when current key expires
        if (storedTTL !== null && storedTTL > 0) {
            const remainingTime = storedTTL - Math.floor(Date.now() / 1000);
            const refreshTime = Math.max((remainingTime - 5) * 1000, 0); // Refresh 5 seconds before expiration

            const refreshTimeout = setTimeout(() => {
                fetchAlgoliaKey();
            }, refreshTime);

            return () => {
                isActive = false;
                clearTimeout(refreshTimeout);
            };
        }

        return () => {
            isActive = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [algoliaKey, storedTTL]);

    const value: AlgoliaContextValue = useMemo(
        () => ({
            algoliaKey,
            loading,
            error,
        }),
        [algoliaKey, error, loading],
    );

    return <AlgoliaContext.Provider value={value}>{children}</AlgoliaContext.Provider>;
};

export const useAlgolia = (): AlgoliaContextValue => {
    const context = useContext(AlgoliaContext);
    if (!context) {
        throw new Error('useAlgolia must be used within an AlgoliaProvider');
    }
    return context;
};
